// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import SignInReducer from '@src/pages/authentication/register/store/reducer'
import LoginReducer from '@src/pages/authentication/login/store/reducer'
import LogoutReducer from '../../@core/layouts/components/navbar/store/reducer'
import NewProductReducer from '@src/pages/planes/productTab/store/reducers/newProductReducer'
import NewPlanReducer from '@src/pages/planes/planesTab/store/reducers/newPlanReducer'
import ChangeProfileReducer from '@src/pages/perfil/store/reducers/changeProfileReducer'
import GetProductReducer from '@src/pages/planes/productTab/store/reducers/getProductsReducer'
import DetailProductReducer from '@src/pages/planes/productTab/store/reducers/detailProductReducer'
import EditProductReducer from '@src/pages/planes/productTab/store/reducers/editProductReducer'
import GetPlanesReducer from '@src/pages/planes/planesTab/store/reducers/getPlanesReducer'
import GetDetailPlanReducer from '@src/pages/planes/planesTab/store/reducers/getDetailPlanReducer'
import OnboardingReducer from '@src/pages/onboarding/store/reducer'
import EditPlanReducer from '@src/pages/planes/planesTab/store/reducers/editPlanReducer'
import CancelPlanReducer from '@src/pages/planes/planesTab/store/reducers/cancelPlanReducer'
import NewQrReducer from '@src/pages/options/qr/store/reducer'
import GetQrReducer from '@src/pages/planes/venderTab/store/reducer/getQrEmpresaReducer'
import GetClientesReducer from '@src/pages/clientes/store/reducer/getClientesReducer'
import GetDetailClienteReducer from '@src/pages/clientes/store/reducer/getDetailClienteReducer'
import GetSuscripcionClienteReducer from '@src/pages/clientes/store/reducer/getSuscripcionesClienteReducer'
import MailRegistroEmpresaReducer from '../../mailerlite/reducers/mailRegistroEmpresaReducer'
import EliminarProductoReducer from '@src/pages/planes/productTab/store/reducers/eliminarProductoReducer'
import ChangeImgReducer from '@src/pages/options/marca/store/reducers/changeImgReducer'
import NavUserDataReducer from '../../@core/layouts/components/navbar/store/storeUserData/navUserDataReducer'
import DetalleSuscripcionReducer from '@src/pages/clientes/suscripciones/store/reducers/getDetailSuscripcionReducer'
import GetDineroReducer from "@src/pages/dinero/store/reducer/DineroReducer"
import GetEstadisticasReducer from '@src/pages/metricas/store/reducer/EstadisticasReducer'
import DataHomeReducer from '@src/pages/home/store/reducer/dataHomeReducer'
import UpdateUserReducer from '../../@core/layouts/components/navbar/store/updateUserStore/updateUserReducer'
import RestoreEmailReducer from '@src/pages/authentication/forgotPassword/sotore/reducer/sendRestoreEmailReducer'
import SendNewPassReducer from '@src/pages/authentication/resetPassword/sotore/reducer/sendNewPassReducer'
import GetFacturasReducer from '@src/pages/perfil/store/reducers/FacturasReducer'
import GetFacturasEmpresaReducer from '@src/pages/perfil/store/reducers/FacturasEmpresaReducer'
import RefreshTokenReducer from '../../auth/refreshToken/refreshTokenReducer'
import GetDatosFiscalesReducer from '../../pages/options/store/reducers/getDatosFiscalesReducer'
import GetDatosBancariosReducer from '../../pages/options/store/reducers/getDatosBancariosReducer'
import PostDatosFiscalesReducer from '../../pages/options/store/reducers/postDatosFiscalesReducer'
import PostDatosBancariosReducer from '../../pages/options/store/reducers/postDatosBancariosReducer'
import AnularSuscripcionReducer from '../../pages/clientes/suscripciones/store/reducers/putAnularSuscripcionReducer'
import AppVersionReducer from '../../pages/authentication/login/store/versionReducer'
import GetAyudaEmpresaReducer from '../../pages/faq/store/reducers/getAyudaEmpresaReducer'
import GetDatosFacturacionReducer from '../../pages/options/store/reducers/getDatosFacturacionReducer'
import PutDatosFacturacionReducer from '../../pages/options/store/reducers/putDatosFacturacionReducer'
import FrontVersionReducer from '../../pages/home/store/reducer/frontVersionReducer'
import UpdateVersionReducer from '../../pages/home/store/reducer/updateFrontVersionReducer'
import PaisReducer from '../../pages/authentication/register/store/paisReducer'
import infoCbuReducer from './infoCbu/infoCbuReducer'
import infoPlanesReducer from './infoPlanes/infoPlanes'
import validacionReducer from './validacion/validacionReducer'
import GetTipoFacturacionReducer from '../../pages/options/store/reducers/getTipoFacturacion'
import PutNombreURLReducer from './modificarLink/PutNombreURLReducer'
import getFacturaReducer from '../../pages/clientes/store/reducer/getInvoiceClienteReducer'
import PutDatosBancariosReducer from '../../pages/options/store/reducers/updateDatosBancariosReducer'
import PutDatosFiscalesReducer from '../../pages/options/store/reducers/updateDatosFiscalesReducer'
import GetDataValidationReducer from '../../pages/vender/store/reducers/GetDataValidationReducer'
import GetComprobantesReducer from '../../pages/clientes/store/reducer/getComprobantesReducer'
import postSendInvoiceReducer from '../../pages/clientes/store/reducer/postSendInvoiceReducer'
import getReceiptReducer from '../../pages/clientes/store/reducer/getReceiptReducer'
import GetFechaReducer from '../../pages/metricas/store/reducer/FechaAltaReducer'
import GetComprobanteClienteReducer from '../../pages/clientes/store/reducer/getComprobantesClienteReducer'
import PostEstadoVenderReducer from '../../pages/vender/store/reducers/PostEstadoVenderReducer'
import newPagoReducer from '../../pages/clientes/pagosManual/store/reducers/newPagoReducer'
import GetClienteManualReducer from '../../pages/clientes/pagosManual/store/reducers/getClienteManualReducer'
import PutDatosBajaClienteReducer from '../../pages/options/store/reducers/putBajaClienteReducer'
import GetDatosBajaClienteReducer from '../../pages/options/store/reducers/getTipoBajaClienteReducer'
import FetchEditInputBajaCliente from '../../pages/options/store/actions/editInputBajaClienteAction'
import GetActividadReducer from '../../pages/actividad/store/reducer/ActividadReducer'
import GetNewDineroReducer from '../../pages/dinero/store/reducer/NewDineroReducer'
import GetRolesReducer from '../../pages/options/store/reducers/getRolesReducer'
import GetUsuarioRolReducer from '../../pages/options/store/reducers/getUsuarioRol'
import PostRolesReducer from '../../pages/options/store/reducers/postRolesReducer'
import PostUsuarioRolesReducer from '../../pages/options/store/reducers/postUsuarioRolesReducer'
import CancelRolReducer from '../../pages/options/store/reducers/putRolReducer'
import CancelUsuarioRolReducer from '../../pages/options/store/reducers/putUsuarioRolReducer'
import EditRolReducer from '../../pages/options/store/reducers/editRolReducer'
import EditUsuarioRolReducer from '../../pages/options/store/reducers/editUserRolReducer'
import GetDetalleRolReducer from '../../pages/options/store/reducers/detalleRolReducer'
import GetDetalleUsuarioRolReducer from '../../pages/options/store/reducers/detalleUsuarioRolReducer'
import GetEstadoServicioAfiliadoReducer from '../../pages/options/afiliados/store/reducers/GetEstadoServicioAfiliadoReducer'
import PutActivarServicioAfiliadoReducer from '../../pages/options/afiliados/store/reducers/PutActivarServicioAfiliadoReducer'
import PutDesactivarServicioAfiliadoReducer from '../../pages/options/afiliados/store/reducers/PutDesactivarServicioAfiliadoReducer'
import PutDesactivarAfiliadoReducer from '../../pages/options/afiliados/store/reducers/PutDesactivarAfiliadoReducer'
import PutActivarAfiliadoReducer from '../../pages/options/afiliados/store/reducers/PutActivarAfiliadoReducer'
import PutComisionAfiliadoReducer from '../../pages/options/afiliados/store/reducers/putCambiarComisionAfiliadoReducer'
import GetObtenerAfiliadosReducer from '../../pages/options/afiliados/store/reducers/GetObtenerAfiliadosReducer'
import GetUrlRegistroAfiliadoReducer from '../../pages/options/afiliados/store/reducers/GetUrlRegistroAfiliadoReducer'
import GetRolEstadoInicioReducer from '../../pages/home/store/reducer/getRolEstadoInicioReducer'
import SelectAccountReducer from '../../pages/roles/store/reducers/selectAccountReducer'
import GetUserReducer from '../../pages/roles/store/reducers/datosLoginReducer'
import GetHomeAfiliadoReducer from './afiliado/GetHomeAfiliadoReducer'
import GetClientesAfiliadoReducer from './afiliado/GetClientesAfiliadoReducer'
import GetPlanesAfiliadoReducer from './afiliado/GetPlanesAfiliadoReducer'
import GetActividadAfiliadoReducer from './afiliado/GetActividadAfiliadoReducer'
import GetMetricasAfiliadoReducer from './afiliado/GetMetricasAfiliadoReducer'
import GetDineroAfiliadoReducer from './afiliado/GetDineroAfiliadoReducer'
import GetVenderValidacionAfiliadoReducer from './afiliado/GetVenderValidacionAfiliadoReducer'
import GetVenderUrlAfiliadoReducer from './afiliado/GetVenderUrlAfiliadoReducer'
import GetUrlVenderReducer from '../../pages/vender/store/reducers/GetUrlVenderReducer'
import PostRegistrarAfiliadoReducer from '../../pages/options/afiliados/store/reducers/PostRegistrarAfiliadoReducer'
import ChangeAvatarImg from '../../pages/options/marca/store/actions/changeAvatarAction'
import UploadSelfieReducer from '../../pages/validacion/store/reducer/uploadSelfieReducer'
import UploadDniDelanteroReducer from '../../pages/validacion/store/reducer/uploadDniDelanteroReducer'
import UploadDniTraseroReducer from '../../pages/validacion/store/reducer/uploadDniTraseroReducer'
import GetDniReducer from '../../pages/validacion/store/reducer/getDniReducer'
import GetValidacionReducer from '../../pages/perfil/store/reducers/getEstadoValidacion'
import GetSuscripcionReducer from "../reducers/reducers-cliente/home-cliente/GetSuscripcionReducer"
import EditUserDataReducer from './reducers-cliente/perfil-cliente/editUserDataReducer'
import GetUserDataReducer from "./reducers-cliente/perfil-cliente/userDataReducer"
import suscripcionListaReducer from "./reducers-cliente/suscripciones-cliente/suscripcionListaReducer"
import GetDetailSubscriptionReducer from './reducers-cliente/suscripciones-cliente/detalleSuscripcionReducer'
import mediosPagoReducer from "./reducers-cliente/medios-cliente/mediosPagoReducer"
import mediosPostReducer from "./reducers-cliente/medios-cliente/mediosPostReducer"
import GetClienteReducer from '../../pages/Perfil-cliente/store/reducers/datosLoginReducer'
import EditClienteReducer from '../../pages/Perfil-cliente/store/reducers/editDatosReducer'
import PutActivarSubReducer from '../reducers/reducers-cliente/suscripciones-cliente/putActivarSubReducer'
import PutAnularSubscriptionReducer from "../reducers/reducers-cliente/suscripciones-cliente/anularSuscripcionReducer"
import EleccionUsuarioReducer from './eleccion-usuario/eleccionUsuarioReducer'
import PutEleccionUsuarioReducer from './eleccion-usuario/updateEleccionUsuarioReducer'
import PutAsociarPlanReducer from '../../pages/options/afiliados/store/reducers/putAsociarPlanReducer'
import PutAsociarDatosBancariosReducer from '../../pages/options/afiliados/store/reducers/putModificarDatosBancariosReducer'
import PutDesactivarPlanReducer from '../../pages/options/afiliados/store/reducers/putDesactivarPlanAfiliadoReducer'
import ObtenerPlanAfiliadoReducer from '../../pages/options/afiliados/store/reducers/GetPlanAsociadoAfiliadoReducer'
import GetValidationMenuReducer from '../../@core/layouts/components/menu/vertical-menu/store/reducers/GetValidationMenuReducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  SignInReducer,
  LoginReducer,
  LogoutReducer,
  NewProductReducer,
  NewPlanReducer,
  ChangeProfileReducer,
  GetProductReducer,
  DetailProductReducer,
  EditProductReducer,
  GetPlanesReducer,
  GetDetailPlanReducer,
  OnboardingReducer,
  EditPlanReducer,
  CancelPlanReducer,
  NewQrReducer,
  GetQrReducer,
  GetClientesReducer,
  GetDetailClienteReducer,
  GetSuscripcionClienteReducer,
  MailRegistroEmpresaReducer,
  EliminarProductoReducer,
  ChangeImgReducer,
  NavUserDataReducer,
  DetalleSuscripcionReducer,
  GetDineroReducer,
  GetEstadisticasReducer,
  DataHomeReducer,
  UpdateUserReducer,
  RestoreEmailReducer,
  SendNewPassReducer,
  GetFacturasReducer,
  GetFacturasEmpresaReducer,
  RefreshTokenReducer,
  GetDatosFiscalesReducer,
  GetDatosBancariosReducer,
  PostDatosFiscalesReducer,
  PostDatosBancariosReducer,
  AnularSuscripcionReducer,
  AppVersionReducer,
  GetAyudaEmpresaReducer,
  GetDatosFacturacionReducer,
  PutDatosFacturacionReducer,
  FrontVersionReducer,
  UpdateVersionReducer,
  PaisReducer,
  infoCbuReducer,
  infoPlanesReducer,
  validacionReducer,
  GetTipoFacturacionReducer,
  PutNombreURLReducer,
  getFacturaReducer,
  PutDatosBancariosReducer,
  PutDatosFiscalesReducer,
  GetDataValidationReducer,
  GetComprobantesReducer,
  postSendInvoiceReducer,
  getReceiptReducer,
  GetFechaReducer,
  GetComprobanteClienteReducer,
  PostEstadoVenderReducer,
  newPagoReducer,
  GetClienteManualReducer,
  PutDatosBajaClienteReducer,
  GetDatosBajaClienteReducer,
  FetchEditInputBajaCliente,
  GetActividadReducer,
  GetNewDineroReducer,
  GetRolesReducer,
  GetUsuarioRolReducer,
  PostRolesReducer,
  PostUsuarioRolesReducer,
  CancelRolReducer,
  CancelUsuarioRolReducer,
  EditRolReducer,
  EditUsuarioRolReducer,
  GetDetalleRolReducer,
  GetDetalleUsuarioRolReducer,
  GetEstadoServicioAfiliadoReducer,
  PutActivarServicioAfiliadoReducer,
  PutDesactivarAfiliadoReducer,
  PutActivarAfiliadoReducer,
  PutComisionAfiliadoReducer,
  PutDesactivarServicioAfiliadoReducer,
  GetObtenerAfiliadosReducer,
  GetUrlRegistroAfiliadoReducer,
  GetRolEstadoInicioReducer,
  SelectAccountReducer,
  GetUserReducer,
  GetHomeAfiliadoReducer,
  GetClientesAfiliadoReducer,
  GetPlanesAfiliadoReducer,
  GetActividadAfiliadoReducer,
  GetMetricasAfiliadoReducer,
  GetDineroAfiliadoReducer,
  GetVenderValidacionAfiliadoReducer,
  GetVenderUrlAfiliadoReducer,
  GetUrlVenderReducer,
  PostRegistrarAfiliadoReducer,
  ChangeAvatarImg,
  UploadSelfieReducer,
  UploadDniDelanteroReducer,
  UploadDniTraseroReducer,
  GetDniReducer,
  GetValidacionReducer,
  GetSuscripcionReducer,
  EditUserDataReducer,
  GetUserDataReducer,
  suscripcionListaReducer,
  GetDetailSubscriptionReducer,
  mediosPagoReducer,
  mediosPostReducer,
  GetClienteReducer,
  EditClienteReducer,
  PutAnularSubscriptionReducer,
  PutActivarSubReducer,
  EleccionUsuarioReducer,
  PutEleccionUsuarioReducer,
  PutAsociarPlanReducer,
  PutAsociarDatosBancariosReducer,
  PutDesactivarPlanReducer,
  ObtenerPlanAfiliadoReducer,
  GetValidationMenuReducer
})

export default rootReducer
